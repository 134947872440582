export var AppGlobals = {
  // URL: 'http://localhost:8084/api/',
  // URL: 'https://app.myhuntingapp.co.za/api/',  
  URL: 'https://test.myhuntingapp.co.za/api/',  
  VERSION: '1.2.3',
  HEADERS: new Headers({ 'Content-Type': 'application/json' }),
  GOOGLE_API_KEY: 'AIzaSyAiMPgM5rGpQ7jq7jAp-18MZVMbV4BvZO8',
  ONE_SIGNAL_KEY: '733436fb-1529-48e1-aca3-42b188fdca97',
  UPLOAD_CARE_KEY: '891328a4fb9e66dfa944',
  PHOTO_CROP: '800x800',
  PROFILE_CROP: '100x100'
};
