import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Lookup } from '../models/lookup.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable()
export class AppDataProvider {
  public user: User;
  public lookupData: Array<Lookup>;
  public desktop: boolean;
  public appComponent: any;

  constructor(private domSanitizer: DomSanitizer) {
    this.desktop = false;
  }

  setUser(user: User) {
    this.user = user;
  }

  setLookupData(data) {
    this.lookupData = data;
  }

  setDesktop(desktop: boolean) {
    this.desktop = desktop;
  }

  isDesktop() {
    return this.desktop;
  }

  getLandingPage() {}

  getSafeProfileImage(picture) {
    if (picture !== undefined && picture !== null && picture !== '') {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(picture);
    } else {
      return '/assets/avatar.png';
    }
  }

  getSafeImage(picture) {
    if (picture !== undefined && picture !== null && picture !== '') {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(picture);
    } else {
      return '/assets/placeholder.png';
    }
  }

  getSafeURL(url) {
    if (url !== undefined && url !== null && url !== '') {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      // return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return '';
    }
  }
}
