import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { Location } from '@angular/common';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AppGlobals } from './app.globals';
import { AppDataProvider } from './app.data.provider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { NgZone } from '@angular/core';
import { URLSearchParams } from 'url';
import { ViewFarmPage } from './search-results/view-farm/view-farm.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = this.commonService.hunterPages;

  constructor(
    private router: Router,
    public commonService: CommonService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private modalController: ModalController,
    private alertController: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private location: Location,
    private navCtrl: NavController,
    private appDataProvider: AppDataProvider,
    private http: HttpClient,
    private mobileAccessibility: MobileAccessibility,
    private deeplinks: Deeplinks,
    private zone: NgZone,
  ) {
    this.mobileAccessibility.setTextZoom(100);
    this.mobileAccessibility.updateTextZoom();
    this.mobileAccessibility.usePreferredTextZoom(false);

    this.appDataProvider.appComponent = this;

    if (window.location.href.indexOf('/share-') !== -1) {
      const sharelink = window.location.href;
      localStorage.setItem('share', sharelink);
    }

    this.initializeApp();
  }

  public initializeApp() {
    this.platform.ready().then(() => {
      this.deeplinks
        .route({
          '/share-farm/:id': ViewFarmPage,
          // '/universal-links-test': AboutPage,
          // '/products/:productId': ProductPage
        })
        .subscribe(
          match => {
            // match.$route - the route we matched, which is the matched entry from the arguments to route()
            // match.$args - the args passed in the link
            // match.$link - the full link data
            localStorage.setItem('deeproute', match.$link.path);
            localStorage.setItem('deepid', match.$args.id);
          },
          nomatch => {
            // nomatch.$link - the full link data
            // alert('No match: ' + JSON.stringify(nomatch));
          },
        );

      if (this.platform.is('desktop')) {
        this.appDataProvider.setDesktop(true);
      } else {
        this.appDataProvider.setDesktop(false);
      }
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);

      // this.setupDeeplinks();

      /* ES ENABLE THIS ON DEVICE */
      // if (this.appDataProvider.isDesktop()) {
      //   window['plugins'].OneSignal.setLogLevel({
      //     logLevel: 6,
      //     visualLevel: 0,
      //   });

      //   let that = this;
      //   var notificationReceived = function (jsonData) {
      //     that.loadUserInfo();
      //   };

      //   var iosSettings = {};
      //   iosSettings['kOSSettingsKeyAutoPrompt'] = false;
      //   iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

      //   window['plugins'].OneSignal.startInit(AppGlobals.ONE_SIGNAL_KEY)
      //     .handleNotificationOpened(notificationReceived)
      //     .handleNotificationReceived(notificationReceived)
      //     .iOSSettings(iosSettings)
      //     .inFocusDisplaying(
      //       window['plugins'].OneSignal.OSInFocusDisplayOption.Notification,
      //     )
      //     .endInit();

      //   window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(
      //     function (accepted) {
      //       if (!accepted) {
      //         this.showMessage(
      //           'Warning',
      //           '',
      //         );
      //       }
      //     },
      //   );

      //   window['plugins'].OneSignal.getIds(function (ids) {
      //     localStorage.setItem('deviceid', ids.userId);
      //   });
      // }
    });

    try {
      this.platform.backButton.subscribeWithPriority(999, async () => {
        console.log(' clicked backbutton on appcomponent event');

        const modal = await this.modalController.getTop();
        if (modal) {
          await this.modalController.dismiss();
          return;
        }

        const alert = await this.alertController.getTop();
        if (alert) {
          await alert.dismiss();
          return;
        }

        const actionSheetCtrl = await this.actionSheetCtrl.getTop();
        if (actionSheetCtrl) {
          await actionSheetCtrl.dismiss();
          return;
        }
        if (
          this.router.url.includes('/login') ||
          this.router.url.includes('/tabs/home')
        ) {
          console.log('Do you want close app?');
          // @ts-ignore
          navigator.app.exitApp();
        } else if (
          this.router.url.includes('/tabs/') &&
          !this.router.url.includes('/tabs/home')
        ) {
          console.log('-----homepage----');
          this.location.back();
        } else {
          console.log('-----subpage----');
          this.navCtrl.back();
        }
      });
    } catch (err) {
      console.log('This is normal in a browser', err);
    }

    this.authenticateUser();
  }

  async logout() {
    localStorage.clear();
    this.appPages = this.commonService.hunterPages;
    await this.router.navigate(['/sign-in']);
  }

  selectProfileType(title: string) {
    if (title === 'My Hunting Profile') {
      localStorage.setItem('profileType', 'hunter');
    } else {
      localStorage.setItem('profileType', 'farmer');
    }
  }

  getNewToken() {
    const authData = {
      deviceid: localStorage.getItem('deviceid'),
      id: localStorage.getItem('userid'),
      devicename: 'Unknown',
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.http
      .post<any>(AppGlobals.URL + 'auth/loginid', authData, { headers })
      .subscribe(
        async data => {
          localStorage.setItem('token', data.token);
          this.authenticateUser();
        },
        async err => {
          this.router.navigate(['/sign-in']);
        },
      );
  }

  authenticateUser() {
    if (!localStorage.getItem('userid')) {
      this.router.navigate(['/sign-in']);
    } else {
      const authData = {
        deviceid: localStorage.getItem('deviceid'),
        id: localStorage.getItem('deviceid'),
        devicename: 'Unknown',
      };

      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-access-token', localStorage.getItem('token'));

      this.http
        .post<any>(AppGlobals.URL + 'auth/me', authData, { headers })
        .subscribe(
          async data => {
            console.log(data);

            if (data.userroleid === 1) {
              this.appPages = this.commonService.hunterPages;
            } else if (data.userroleid === 2) {
              this.appPages = this.commonService.advertiserPages;
            } else {
              this.appPages = this.commonService.hunterAndAdvertiserPages;
            }
            this.loadLookupData();

            this.appDataProvider.setUser(data);

            this.router.url.includes('/tabs/home');
          },
          async err => {
            this.getNewToken();
          },
        );
    }
  }

  loadUserInfo() {
    const authData = {
      deviceid: localStorage.getItem('deviceid'),
      devicename: 'Unknown',
    };
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', localStorage.getItem('token'));

    this.http
      .post<any>(AppGlobals.URL + 'auth/me', authData, { headers })
      .subscribe(
        async data => {
          this.appDataProvider.setUser(data);

          setTimeout(() => {
            this.router.url.includes('/tabs/home');
          }, 1000);
        },
        async err => {},
      );
  }

  loadLookupData() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', localStorage.getItem('token'));
    this.http.get<any>(AppGlobals.URL + 'lookups', { headers }).subscribe(
      async data => {
        this.appDataProvider.setLookupData(data);
      },
      async err => {},
    );
  }

  // setupDeeplinks() {
  //   this.deeplinks.route({ '/:slug': 'farms' }).subscribe(
  //     match => {
  //       console.log('Successfully matched route', match);
  //       // Create our internal Router path by hand
  //       const internalPath = `/${match.$route}/${match.$args['slug']}`;

  //       // Run the navigation in the Angular zone
  //       this.zone.run(() => {
  //         this.router.navigateByUrl(internalPath);
  //       });
  //     },
  //     nomatch => {
  //       // nomatch.$link - the full link data
  //       console.error("Got a deeplink that didn't match", nomatch);
  //     },
  //   );
  // }
}
