import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public hunterPages = [
    {
      title: 'Find / Book Hunting Trip',
      url: '/tabs/home',
      image: 'home.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'Map View',
      url: '/map',
      image: 'map-pin.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Wildlife Auctions Calendar',
      url: '/browse-auctions',
      image: 'Browse-Weapons-Directory.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'The Hunters Market',
      url: '/browse-ads',
      image: 'Browse-Advertisements.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'Browse Weapons',
      url: '/browse-weapons',
      image: 'My-Weapons.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Hunting Photos',
      url: '/browse-hunting-photos',
      image: 'Browse-Hunting-Photos.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'My Hunting Profile',
      url: '/my-profile',
      image: 'My-Profile.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Favourites',
      url: '/my-favourites',
      image: 'My-Favourites.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Weapons',
      url: '/my-weapons',
      image: 'My-Weapons.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Shot Placements',
      url: '/shot-placements',
      image: 'browse-wild-life.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Notifications',
      url: '/notifications',
      image: 'notification.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Change Password',
      url: '/change-password',
      image: 'password.svg',
      direction: 'forward',
      display: true,
    },
  ];

  public advertiserPages = [
    {
      title: 'Find / Book Hunting Trip',
      url: '/tabs/home',
      image: 'home.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'Map View',
      url: '/map',
      image: 'map-pin.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Wildlife Auctions Calendar',
      url: '/browse-auctions',
      image: 'browse-wild-life.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'The Hunters Market',
      url: '/browse-ads',
      image: 'Browse-Advertisements.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'Browse Weapons',
      url: '/browse-weapons',
      image: 'My-Weapons.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Hunting Photos',
      url: '/browse-hunting-photos',
      image: 'Browse-Hunting-Photos.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'My Farm Profile',
      url: '/my-profile',
      image: 'My-Profile.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Wildlife Auctions',
      url: '/my-wildlife-auctions',
      image: 'browse-wild-life.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Business',
      url: '/my-ads',
      image: 'My-Advertisements.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Weapons',
      url: '/my-weapons',
      image: 'My-Weapons.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Favourites',
      url: '/my-favourites',
      image: 'My-Favourites.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Shot Placements',
      url: '/shot-placements',
      image: 'browse-wild-life.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Notifications',
      url: '/notifications',
      image: 'notification.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Change Password',
      url: '/change-password',
      image: 'password.svg',
      direction: 'forward',
      display: true,
    },
  ];

  public hunterAndAdvertiserPages = [
    {
      title: 'Find / Book Hunting Trip',
      url: '/tabs/home',
      image: 'home.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'Map View',
      url: '/map',
      image: 'map-pin.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Wildlife Auctions Calendar',
      url: '/browse-auctions',
      image: 'browse-wild-life.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'The Hunters Market',
      url: '/browse-ads',
      image: 'Browse-Advertisements.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'Browse Weapons',
      url: '/browse-weapons',
      image: 'My-Weapons.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Hunting Photos',
      url: '/browse-hunting-photos',
      image: 'Browse-Hunting-Photos.svg',
      direction: 'root',
      display: true,
    },
    {
      title: 'My Farm Profile',
      url: '/my-profile',
      image: 'My-Profile.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Hunting Profile',
      url: '/my-profile',
      image: 'My-Profile.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Wildlife Auctions',
      url: '/my-wildlife-auctions',
      image: 'browse-wild-life.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Business',
      url: '/my-ads',
      image: 'My-Advertisements.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Weapons',
      url: '/my-weapons',
      image: 'My-Weapons.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'My Favourites',
      url: '/my-favourites',
      image: 'My-Favourites.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Shot Placements',
      url: '/shot-placements',
      image: 'browse-wild-life.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Notifications',
      url: '/notifications',
      image: 'notification.svg',
      direction: 'forward',
      display: true,
    },
    {
      title: 'Change Password',
      url: '/change-password',
      image: 'password.svg',
      direction: 'forward',
      display: true,
    },
  ];

  isLoggedIn = new BehaviorSubject(false);

  constructor(
    private toastCtrl: ToastController,
    private navController: NavController,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    private platform: Platform,
    private alertController: AlertController,
  ) {}

  async presentLoading() {
    this.loadingCtrl.getTop().then(v => {
      if (v === undefined) {
        this.loadingCtrl
          .create({
            spinner: 'bubbles',
          })
          .then(res => {
            res.present();
          });
      } else {
        // this.dismissLoading();
      }
    });
  }

  async dismissLoading(reload?: number) {
    let i = 0;
    if (reload) {
      i += reload;
    }
    return this.loadingCtrl.getTop().then(v => {
      if (v !== undefined) {
        v.dismiss()
          .then(res => {
            console.log('Loading dismissed!', res);
            if (!res) {
              this.loadingCtrl.dismiss();
            } else {
              setTimeout(() => {
                if (i <= 2) {
                  this.dismissLoading(i);
                }
                return;
              }, 2000);
            }
          })
          .catch(error => {
            console.log('error', error);
          });
      } else {
        setTimeout(() => {
          if (i <= 2) {
            this.dismissLoading(i);
          }
          return;
        }, 2000);
      }
    });
  }

  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2500,
      position: 'bottom',
    });
    await toast.present();
  }

  async showErrorToast(message?: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'bottom',
      color: 'danger',
    });
    await toast.present();
  }

  async showSuccessToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'bottom',
      color: 'success',
    });
    await toast.present();
  }

  // getToken() {
  //   return localStorage.getItem('token');
  // }

  getProfileType() {
    return localStorage.getItem('profileType');
  }

  getProfileTypeMenu() {
    return localStorage.getItem('profileTypeMenu');
  }

  loggedIn() {
    return localStorage.getItem('token') != null;
  }

  async logOut() {
    this.isLoggedIn.next(false);
    localStorage.clear();
    await this.navController.navigateRoot('/', { animated: true });
  }

  getDataFromLocal() {
    return JSON.parse(localStorage.getItem('data'));
  }

  async appExitConfirm() {
    const alert = await this.alertController.create({
      header: 'Exit?',
      message: 'Are you sure to exit?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Okay',
          handler: () => {
            // @ts-ignore
            navigator.app.exitApp();
          },
        },
      ],
    });
    await alert.present();
  }

  getMobileType() {
    if (this.platform.is('android')) {
      return 1;
    } else if (this.platform.is('ios')) {
      return 0;
    }
  }

  getImage(value: string): string {
    return environment.imgUrl + value;
  }

  async showMessage(title, message) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async confirmYesNoMessage(title, message) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: title,
        message: message,
        buttons: [
          {
            text: 'Yes',
            handler: () => {
              resolve(true);
            },
          },
          {
            text: 'No',
            handler: () => {
              reject(true);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  readMore = (str, max = 20) => {
    if (str === undefined || str === null) return '';
    const array = str.trim().split(' ');
    const ellipsis = array.length > max ? '...' : '';

    return array.slice(0, max).join(' ') + ellipsis;
  };
}
