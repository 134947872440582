import { User } from '../../models/user.model';

export function getFarmImage(user: User) {
  if (user === null || user === undefined) {
    return './assets/images/avatar.png';
  } else if (user.farmimage === null || user.farmimage === '') {
    if (user.userroleid === 2 || user.userroleid === 3) {
      return './assets/images/logoplaceholder.png';
    } else {
      return './assets/images/avatar.png';
    }
  } else {
    return user.farmimage;
  }
}

export function getAvatar(user: User) {
  if (user === null || user === undefined) return './assets/images/avatar.png';
  else if (user.profileimage === null || user.profileimage === '')
    return './assets/images/avatar.png';
  else return user.profileimage;
}

export function getImage(image: any) {
  if (image === null) return './assets/images/placeholder.png';
  else return image;
}

export function getBanner(user: User) {
  if (user === null || user === undefined)
    return './assets/images/placeholder.png';
  else if (user.farmbanner === null || user.farmbanner === '')
    return './assets/images/placeholder.png';
  else return user.farmbanner;
}

export function setDefaultImage(collection: any) {
  if (collection === null || collection === undefined) return;
  collection.map(item => {
    item.defaultimage = './assets/images/placeholder.png';
    if (
      item.photosjson != null &&
      item.photosjson != undefined &&
      item.photosjson != ''
    ) {
      let images = JSON.parse(item.photosjson);
      if (images.length > 0) item.defaultimage = images[0];
    }
  });
}
