export const MsgSuccess = 'Success!';
export const MsgErrorTitle = 'Sorry!';
export const MsgCommsError =
  'Unable to communicate with the server. Please check your connection.';
export const MsgConfirmDelete = 'Are you sure you want to delete this?';
export const MsgInvalidEmailorPassword = 'Invalid email address or password.';
export const MsgInvalidEmail = 'Invalid email address.';
export const MsgInvalidPassword = 'Invalid password.';
export const MsgUpdatedSuccess = 'Details updated successfully!';
export const MsgResetPassword =
  'Thank you, please check your email for instructions to reset your password.';
export const MsgFavourited = 'Saved under your favourites!';
export const MsgRemovedFavourited = 'Removed from your favourites!';
export const MsgNoDetails = 'No details available.';
export const MsgComment = 'Comment sent.';
export const MsgRatingSubmitted = 'Thank you for your rating.';
export const MsgFlagComment =
  'Are you sure you want to flag this comment as unappropriate? The comment will be reviewed by an administrator that will have the option re-instate or remove it permanently.';
export const MsgBookingRequestSent =
  'Your booking request was sent to the farm, please expect a response within 48 hours.';
export const MsgBookingSpecifyHunters =
  'Please specify the hunters that will be accompanying you on the trip.';
export const MsgDates = 'To date cannot be before from date.';
export const MsgDatesRequired =
  'Please specify the dates for your planned hunting trip.';
export const MsgNumberOfHunters =
  'Please specify the number of hunters accompanying.';
