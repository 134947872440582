import {
  MsgFavourited,
  MsgNoDetails,
  MsgRemovedFavourited,
} from './../../helpers/language';
import { User } from 'src/models/user.model';
import { Component, OnInit } from '@angular/core';
import { CalendarComponentOptions } from 'ion2-calendar';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { CommonService } from '../../common.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { getAvatar, setDefaultImage } from '../../helpers/utils';
import { AppDataProvider } from '../../app.data.provider';
import { FarmGame } from 'src/models/farmgame.model';
import { Accomodation } from 'src/models/accommodation.model';
import { Favorite } from 'src/models/favorite.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppGlobals } from 'src/app/app.globals';
import { Search } from './../../../models/search.model';
import { Booking } from './../../../models/booking.model';
import {
  MsgCommsError,
  MsgErrorTitle,
  MsgSuccess,
} from 'src/app/helpers/language';
import moment from 'moment';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-farm',
  templateUrl: './view-farm.page.html',
  styleUrls: ['./view-farm.page.scss'],
})
export class ViewFarmPage implements OnInit {
  public safePipedYoutube: SafeUrl;
  validDates: boolean = false;
  searchData: Search;
  farmRating: number = 0;
  sharetype: string = 'farm';
  record: User;
  booking: Booking;
  photos: Array<string>;
  accList: Array<Accomodation> = null;
  loadingHidden: boolean = false;
  avatar: string;
  defaultimage: string = './assets/images/placeholder.png';
  segmentModel = 'details';
  slideOpts = {
    slidesPerView: 3.8,
    spaceBetween: 10,
  };
  dateMulti: { from: string; to: string };
  type: 'string';
  optionsRange: CalendarComponentOptions = {
    pickMode: 'range',
    color: 'primary',
  };
  gameList: Array<FarmGame> = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public menu: MenuController,
    public commonService: CommonService,
    private socialSharing: SocialSharing,
    private photoViewer: PhotoViewer,
    private callNumber: CallNumber,
    public appDataProvider: AppDataProvider,
    private http: HttpClient,
  ) {
    this.record = new User();
  }

  ngOnInit() {
    this.avatar = getAvatar(this.appDataProvider.user);
    if (
      this.appDataProvider.user === null ||
      this.appDataProvider.user === undefined ||
      this.appDataProvider.lookupData === null ||
      this.appDataProvider.lookupData === undefined ||
      this.appDataProvider.lookupData.length === 0
    ) {
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
      return;
    }

    this.photos = new Array<string>();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.record) {
        this.record = JSON.parse(params.record);
        console.log(this.record.availabilityjson);
        this.safePipedYoutube = this.appDataProvider.getSafeURL(
          this.record.youtube,
        );
        this.searchData = JSON.parse(params.searchData);
        this.avatar = getAvatar(this.record);

        this.booking = new Booking();
        this.booking.fromdate = moment(
          new Date(this.searchData.fromdate),
        ).format('YYYY-MM-DD');
        this.booking.todate = moment(new Date(this.searchData.todate)).format(
          'YYYY-MM-DD',
        );
        this.booking.userid = this.appDataProvider.user.id;
        this.booking.farmid = this.record.id;
        this.booking.speciesjson = this.searchData.species;
        if (this.searchData.gun) this.booking.gun = this.searchData.gun;
        if (this.searchData.bow) this.booking.bow = this.searchData.bow;
        if (this.searchData.catering)
          this.booking.cateringoptionid = this.searchData.catering;
        else this.booking.cateringoptionid = 0;
        if (this.searchData.observers)
          this.booking.observers = this.searchData.observers;
        if (this.searchData.hunters)
          this.booking.hunters = this.searchData.hunters;
        else this.booking.hunters = 0;

        this.booking.bookingstatusid = 600;

        this.loadingHidden = true;

        if (this.record.rating) this.farmRating = this.record.rating;

        let daysConfig = [];
        let availableDaysArr = JSON.parse(this.record.availabilityjson);
        if (availableDaysArr != undefined && availableDaysArr != null) {
          availableDaysArr.map(availableDay => {
            let date = new Date(availableDay);
            date.setHours(0, 0, 0, 0);

            let disabled = false;
            if (date < new Date()) disabled = true;

            daysConfig.push({
              date: moment(date).format('YYYY-MM-DD'),
              marked: disabled ? false : true,
              disable: disabled,
            });
          });
        }

        var checkDate = moment(new Date());
        for (let i = 0; i <= 1095; i++) {
          checkDate = checkDate.add(1, 'days');

          if (
            daysConfig.filter(config => {
              return config.date == checkDate.format('YYYY-MM-DD');
            }).length == 0
          ) {
            daysConfig.push({
              date: checkDate.format('YYYY-MM-DD'),
              marked: false,
              disable: true,
            });
          }
        }

        this.optionsRange.daysConfig = daysConfig;

        if (this.record.provinceid != null)
          this.record.province_desc = this.appDataProvider.lookupData.filter(
            item => item.id == this.record.provinceid,
          )[0].lookupvalue;

        if (this.record.registered == true) {
          this.record.reg = 'Yes';
        } else {
          this.record.reg = 'No';
        }

        if (
          this.record.information === undefined ||
          this.record.information === null ||
          this.record.information === ''
        )
          this.record.information = MsgNoDetails;

        let alteredGameList = [];

        if (
          this.record.farmgame !== undefined &&
          this.record.farmgame !== null
        ) {
          this.record.farmgame.map(record => {
            if (record.animalid != null)
              record.animalid_desc = this.appDataProvider.lookupData.filter(
                item => item.id == record.animalid,
              )[0].lookupvalue;
            alteredGameList.push(record);
          });
          this.gameList = alteredGameList;
          setDefaultImage(this.gameList);
        }

        if (
          this.record.accomodation !== undefined &&
          this.record.accomodation !== null
        ) {
          this.accList = this.record.accomodation;
          setDefaultImage(this.accList);
        }

        let farmPhotos = [];
        if (this.accList != null) {
          this.accList.map(acc => {
            if (acc.defaultimage) farmPhotos.push(acc.defaultimage);
          });
        }
        if (this.gameList != null) {
          this.gameList.map(game => {
            if (game.defaultimage) farmPhotos.push(game.defaultimage);
          });
        }
        this.photos = farmPhotos;
      }
    });
  }

  segmentChanged(event) {
    console.log(this.segmentModel);
    console.log(event);
  }

  async onChange($event) {
    let fromDateOk = false;
    let toDateDateOk = false;
    this.optionsRange.daysConfig.map(availableDay => {
      if (
        $event.from.format('YYYY-MM-DD') ===
        moment(availableDay.date).format('YYYY-MM-DD')
      ) {
        this.booking.fromdate = $event.from.format('YYYY-MM-DD');
        fromDateOk = true;
      }
      if (
        $event.to.format('YYYY-MM-DD') ===
        moment(availableDay.date).format('YYYY-MM-DD')
      ) {
        this.booking.todate = $event.to.format('YYYY-MM-DD');
        toDateDateOk = true;
      }
    });

    if (!fromDateOk || !toDateDateOk) {
      this.validDates = false;
      this.commonService.showMessage(
        'Sorry!',
        'The selected date range is not available. Please choose dates marked in green.',
      );
    } else this.validDates = true;
  }

  viewPhoto(photo) {
    console.log('e');
    if (this.appDataProvider.isDesktop()) {
      window.open(photo, '_blank');
    } else {
      this.photoViewer.show(photo);
    }
  }

  shareThis() {
    this.socialSharing.share(
      this.record.farmname + ' ' + this.record.address,
      null,
      null,
      'https://www.myhuntingapp.co.za/share-farm/' + this.record.id.toString(),
    );
  }

  addToFavorite() {
    this.loadingHidden = false;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', localStorage.getItem('token'));

    if (this.record.isfavorite && this.record.isfavorite !== 0) {
      this.http
        .delete<any>(AppGlobals.URL + 'favorite/' + this.record.isfavorite, {
          headers,
        })
        .subscribe(
          async data => {
            this.loadingHidden = true;
            this.record.isfavorite = 0;
            this.commonService.showMessage(MsgSuccess, MsgRemovedFavourited);
          },
          async err => {
            this.loadingHidden = true;
            if (err.status === 401) await this.router.navigate(['/sign-in']);
            else if (err != null && err.error != null) {
              this.commonService.showMessage(MsgErrorTitle, err.error.message);
            } else this.commonService.showMessage(MsgErrorTitle, MsgCommsError);
          },
        );
    } else {
      const recordData: Favorite = {
        userid: this.appDataProvider.user.id,
        farmid: this.record.id,
      };

      this.http
        .post<any>(
          AppGlobals.URL + 'favorite/',
          recordData, // data object
          { headers },
        )
        .subscribe(
          async data => {
            this.loadingHidden = true;
            this.record.isfavorite = data.id;
            this.commonService.showMessage(MsgSuccess, MsgFavourited);
          },
          async err => {
            this.loadingHidden = true;
            if (err.status === 401) await this.router.navigate(['/sign-in']);
            else if (err != null && err.error != null) {
              this.commonService.showMessage(MsgErrorTitle, err.error.message);
            } else this.commonService.showMessage(MsgErrorTitle, MsgCommsError);
          },
        );
    }
  }

  viewGame(gameRecord) {
    let recordToPass = {
      ...gameRecord,
      user: this.record,
    };
    let navigationExtras: NavigationExtras = {
      queryParams: {
        record: JSON.stringify(recordToPass),
      },
    };
    this.router.navigate(['/view-game'], navigationExtras);
  }

  viewAccomodation(accRecord) {
    let recordToPass = {
      ...accRecord,
      user: this.record,
    };
    let navigationExtras: NavigationExtras = {
      queryParams: {
        record: JSON.stringify(recordToPass),
      },
    };
    this.router.navigate(['/view-accommodation'], navigationExtras);
  }

  // bookJumpToCalendar() {
  //   this.segmentModel = 'availability';
  // }

  viewRatings() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        record: JSON.stringify(this.record),
      },
    };
    this.router.navigate(['/view-ratings'], navigationExtras);
  }

  book() {
    // console.log(this.booking);
    // return;

    if (
      this.booking.fromdate === '' ||
      this.booking.fromdate === 'Invalid date'
    ) {
      this.booking.fromdate = moment(new Date()).format('YYYY-MM-DD');
      this.booking.todate = moment(new Date())
        .add('day', 1)
        .format('YYYY-MM-DD');
    }

    let navigationExtras: NavigationExtras = {
      queryParams: {
        record: JSON.stringify(this.record),
        booking: JSON.stringify(this.booking),
      },
    };
    this.router.navigate(['/booking-request'], navigationExtras);
  }
}
